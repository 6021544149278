<template>
  <div class="main analytics-page">
    <div class="main-width">
      <div class="news" v-if="news">
        <h2 class="text-center">{{ news.titleRu }}</h2>
        <div v-html="news.bodyRu"></div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsResource from "../services/NewsResource";
const news = new NewsResource();

export default {
  name: "news-page",
  data() {
    return {
      news: null,
    };
  },
  mounted() {
    this.loadArticle()
  },
  methods: {
    async loadArticle() {
      this.news = await news.getArticle("76698E18-90E5-4691-BA2C-5B72AC18B2F7")
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 40px;
}
.news {
  background-color: #fff;
  padding: 20px 40px;
}
</style>