import axios from 'axios'

const headers = {};

export async function getObject(entity, id) {

  const result = await axios
    .post(
      "/api/uicommand/get/object"
      , {
        entity: entity, uuid: id
      }
      , headers
    )

  return result.status === 200 ? result.data : null
}
/*
function saveObject() {

}

function deleteObject() {

}

function getPage() {

}

function getList() {

}
*/